<template>
  <div class="mobile-nav">
    <div class="bg-white shadow-sm py-2">
      <b-container>
        <div
          class="d-flex py-0 justify-content-between align-items-center gap_1"
        >
          <!-- Logo -->
          <b-img
            class=""
            :src="require('@/assets/images/Souq-Alattara-logo.svg')"
            width="150"
          ></b-img>

          <!-- burger button -->
          <button
            class="bg-transparent border-0 pt-1 text-muted"
            @click="sideNav = true"
          >
            <i class="las la-bars la-2x"></i>
          </button>

          <!-- Side Nav -->
          <side-nav
            :listItems="listItems"
            :sideNav="sideNav"
            @close="sideNav = false"
          />
        </div>
      </b-container>
    </div>
    <bottom-bar />
  </div>
</template>

<script>
import SideNav from "./SideNav.vue";
import BottomBar from "./BottomBar.vue";
export default {
  props: ["listItems"],
  components: { SideNav, BottomBar },
  data() {
    return {
      sideNav: false,
    };
  },
};
</script>
